<template name="component-name">
  <header class="nav-bar-component">
    <a href>
      <i class="iconfont iconlogo nv-component-icon"></i>
    </a>
    <div class="nb-component-info">
      <img src="./../../assets/personal_image.svg" class="nbc-info-img" />
      <span class="nbc-info-name">{{ username }}</span>
      <div class="nbc-info-logout" @click="logout">退出登录</div>
    </div>
  </header>
</template>
<script>
import { requestDeleteUserName } from "./../../request";
import api from "./../../api";
export default {
  data() {
    return {
      username: localStorage.getItem("username")
    };
  },
  methods: {
    logout() {
      requestDeleteUserName(api.logout)
        .then(({ data }) => {
          if (data.code === 2000) {
            localStorage.removeItem("username");
            this.$router.push("/");
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "./../../style/navBar.less";
</style>
