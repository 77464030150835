<template name="component-name">
  <svg
    :class="`iconfont-symbol ${className || ''}`"
    :style="styleObj"
    aria-hidden="true"
  >
    <use :xlink:href="'#' + icon" />
  </svg>
</template>
<script>
export default {
  name: "Iconfont",
  props: {
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    styleObj: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="less">
.iconfont-symbol {
  font-size: 14px;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
