<template>
  <div class="side-bar-component">
    <a-menu
      mode="inline"
      class="ab-component-menu"
      :defaultOpenKeys="['account']"
    >
      <template v-for="item in menu">
        <!-- 一级 -->
        <a-menu-item
          :key="item.key"
          v-if="!(item.children && item.children.length)"
          class="abc-menu-item"
        >
          <router-link :to="item.route" exact-active-class="router-active">
            <i class="iconfont iconaccount"></i>
            <span>{{ item.title }}</span>
          </router-link>
        </a-menu-item>
        <!-- 一级、二级 -->
        <a-sub-menu :key="item.key" class="abc-menu-sbu" v-else>
          <span slot="title">
            <i class="iconfont iconaccount"></i>
            {{ item.title }}
          </span>
          <template v-for="val in item.children">
            <a-menu-item :key="val.key" class="abcm-sub-item">
              <router-link :to="val.route" exact-active-class="router-active">
                {{ val.title }}
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { account } from "./../../router/routes";
export default {
  data() {
    return {
      menu: account
    };
  },
  components: {},
  methods: {}
};
</script>
<style lang="less" scoped>
@import "./../../style/sidebar.less";
</style>
