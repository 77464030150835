<template>
  <div class="breadcrumb-component" v-if="bread.length">
    <a-breadcrumb>
      <template v-for="item in bread">
        <a-breadcrumb-item :key="item">{{ item }}</a-breadcrumb-item>
      </template>
    </a-breadcrumb>
  </div>
</template>
<script>
// import { Breadcrumb } from "ant-design-vue";
const breadObj = {
  generate: ["账户管理", "账户生成"],
  record: ["账户管理", "账户记录"],
  TOSRecord: ["账户管理", "TOS账户记录"]
};
export default {
  props: {},
  watch: {
    $route(to) {
      this.bread = this.bread = breadObj[to.name];
    }
  },
  data() {
    return {
      bread: []
    };
  },
  mounted() {
    this.bread = breadObj[this.$route.name];
  },
  components: {
    // ABreadcrumb: Breadcrumb,
    // ABreadcrumbItem: Breadcrumb.Item
  }
};
</script>
<style lang="less" scoped>
@import "./../../style/breadcrumbBar.less";
</style>
