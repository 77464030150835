export default {
  login: "/api/v1/delivery/login", // 登录
  logout: "/api/v1/delivery/logout", // 退出登录
  datafile: "/api/v1/delivery/datafile", // 选择文件
  temp_auth: "/api/v1/delivery/temp_auth", // 临时授权
  delivery_list: "/api/v1/delivery/delivery_list", // 记录列表
  ak_info: "/api/v1/delivery/ak_info", // 预览信息
  record: "/api/v1/delivery/record", // 删除交互记录
  email: "/api/v1/delivery/email", // 发送邮箱
  resetting: "/api/v1/delivery/resetting", // 重置
  volcano_delivery_list: "/api/v1/delivery/volcano_delivery_list" //tos列表
};
